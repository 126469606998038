import axios from 'axios'
import wx from 'weixin-js-sdk'
axios.defaults.baseURL = 'https://yjemsa.com'

 
export default {
  getOpenId (params: any) {
    return new Promise<void>((resolve, reject) => {
        axios.post('/wxOpenid.php').then((res: any) => {
            window.location.href = res.url
        })
    })
  },
  // 获取微信支付SDK的签名
  getWxPaySign (params: any) {
    return new Promise<void>((resolve) => {
      // 发起请求获取签名
      axios.post('/wp.php?f=d', params).then((data:any) => {
        if (data.code === 0) {
          // 配置微信JS SDK
          wx.config({
            debug: false,
            appId: data.data.appid,
            timestamp: data.data.timestamp,
            nonceStr: data.data.nonceStr,
            signature: data.data.signature,
            jsApiList: [
              'chooseWXPay'
            ]
          })
          // 配置完成后返回一个resolve
          wx.ready(() => {
            resolve(data.data)
          })
        }
      })
    })
  },
 
  // 发起微信支付
  wxPay (params: { timestamp: any; nonceStr: any; package: any; signType: any; paySign: any }) {
    return new Promise((resolve, reject) => {
      // 调用微信支付
      wx.chooseWXPay({
        timestamp: params.timestamp,  
        nonceStr: params.nonceStr,  
        package: params.package,  
        signType: params.signType,  
        paySign: params.paySign,  
        success: (res: unknown) => {
          // 支付成功时返回resolve
          resolve(res)
        },
        fail: (err: any) => {
          // 支付失败时返回reject
          reject(err)
        }
      })
    })
  }
}