import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TabbarItem = _resolveComponent("TabbarItem")!
  const _component_Tabbar = _resolveComponent("Tabbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_Tabbar, {
      placeholder: "",
      route: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabbarItem, {
          icon: "records",
          replace: "",
          to: "/"
        }, {
          default: _withCtx(() => [
            _createTextVNode("查看开班列表")
          ]),
          _: 1
        }),
        _createVNode(_component_TabbarItem, {
          icon: "search",
          replace: "",
          to: "/todoList"
        }, {
          default: _withCtx(() => [
            _createTextVNode("报名缴费查询")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.showFooter]
    ])
  ]))
}