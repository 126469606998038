import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import './assets/css/main.styl'
import BaseComponents from './components/base'
import BaseDirective from './directive'
import { createPinia } from 'pinia'
import { Form, Field, CellGroup, Area, Picker, Popup, DatetimePicker, Loading, Uploader, Cell, Button, Icon, List, Overlay, Dialog } from 'vant';


const app = createApp(App)
app.use(DatetimePicker);
app.use(Loading);
app.use(List);
app.use(Area);
app.use(Icon);
app.use(Button);
app.use(Cell);
app.use(Uploader);
app.use(Form);
app.use(Field);
app.use(Picker);
app.use(Popup);
app.use(CellGroup);
app.use(Overlay);
app.use(Dialog);
app.use(BaseComponents)
app.use(BaseDirective)
app.use(createPinia())

app.use(store).use(router).mount('#app')
