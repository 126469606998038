
import { defineComponent } from "vue";
import { Tabbar, TabbarItem } from "vant";
import wxPay from "@/api/wxpay"
import { useRoute } from "vue-router";
export default defineComponent({
  name: "App",
  components: {
    Tabbar,
    TabbarItem,
  },
  computed: {
    showFooter() {
      return this.$route.path.split("/").length < 3;
    },
  },
  async mounted() {
    await this.$router.isReady()
    let openid = this.hasOpenid()
    if (!openid) {
      let isWx = this.isWeixin()
      if (isWx) {
        wxPay.getOpenId(null)
      }
    }
  },
  methods: {
    isWeixin() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },
    hasOpenid() {
      let openid:any = this.$route.query.openid;
      if (!openid) {
        openid = localStorage.getItem('openid')
      } else {
        localStorage.setItem('openid', openid)
      }
      return openid
    }
  }
})
